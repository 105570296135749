import React, {useState} from 'react'
import Button from '@material-ui/core/Button'

import DefaultModal from './defaultModal'
import FileUploader from '../../../../shared/form/fileUploader'
import getApiUrl from '../../../../../lib/getApiUrl'

export default function LoginBackground(props) {
    const [defaultOpen, setDefaultOpen] = useState(false)
    const [imgDate, setImgDate] = useState(new Date())
    const [fileID, setFileID] = useState(0)

    const handleChange = function() {
        setDefaultOpen(false)
        setImgDate(new Date())
    }

    return(
        <div>
            <h1>
                Favicon
            </h1>
            <p>
                Here you can change the favicon of your system.  The favicon is the small icon that appears in the browser tab.  It should be square, and png or ico file type.
            </p>
            <img 
                src={getApiUrl() + "/file/noCache/favicon.ico"}
                style={{
                    maxWidth: "100px",
                    margin: "10px"
                }}
                key={imgDate}
            />
            <br/>
            <div style={{maxWidth: "350px"}}>
                <FileUploader 
                    onChange={() => setImgDate(new Date())} 
                    value={fileID}
                    id="fileID"
                    disabled={false}
                    //error={props.validationResult.failMessages.hasOwnProperty("fileID")}
                    //helperText={props.validationResult.failMessages["fileID"]}
                    library="favicon"
                    label="a new icon (.ico)"
                    accept="any"
                    urlType="icon"
                />
            </div>
            <Button
                onClick={() => setDefaultOpen(true)}
                variant="contained"
                color="default"
                style={{margin: "10px"}}
            >
                Reset
            </Button>
            <DefaultModal
                open={defaultOpen}
                onChange={handleChange}
            />
        </div>
    )
}