//import cookie from 'react-cookies'
//import history from "./history.js"
//import {withRouter} from 'react-router-dom'
import {getToken, logOut} from './auth'
import getApiUrl from './getApiUrl'

//takes the usual fetch params and adds in the jwt cookie on the header
let authFetch = function(url, data, suppressContentTypeHeader) {
    //suppressContentTypeHeaderr useful for file uploads. false by default
    if(suppressContentTypeHeader === undefined) {
        suppressContentTypeHeader = false
    }
    //prefix the API url
    //console.log("API " + getApiUrl())
    url = getApiUrl() + url
    //add the auth cookie to the header, if it exists
    const token = getToken()
    //console.log("Using token " + token + " for fetching data")
    if(data === undefined) {
        //create the fetch data object if it's not been passed
        data = {method: "GET"}
    }
    //make headers
    if(data.headers === undefined) {
        //adding the missing headers object
        data.headers = {}
    }
    //add content type header.  If not specified this will be application json
    if(!suppressContentTypeHeader) {
        data.headers["Content-Type"] = (data.headers["Content-Type"] === undefined ? "application/json" : data.headers["Content-Type"])
    }
    //add the auth token
    data.headers["x-access-token"] = token
    //return a normal fetch and auth check
    return fetch(url, data)
    .then(response => {
        if(response.status === 403) {
            //unauthorised.  handle this by removing the invalid login cookie and reloading the page
            logOut()
            window.location.reload()
        } else {
            return response.json()
        }
    })
    .catch(err => {
        //console.log(err)
        throw err
    })
}

export default authFetch