function getApiUrl() {
    //if the environment variable is set, use it, otherwise use the default value derived from the application URL
    if(process.env.REACT_APP_API_URL) {
        return process.env.REACT_APP_API_URL;
    } else {
        //the base url, plus the API path
        return (window.location.href + "salt").replace(window.location.pathname + "salt", "/api")
    }
}

export default getApiUrl;