import React from 'react';
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip';

//lib
import {getToken} from '../../../lib/auth'
import getApiUrl from '../../../lib/getApiUrl'

//icons
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

function ExcelDownloadButton(props) {
    function handleClick() {
        window.location.href = getApiUrl() + props.url + (props.url.includes("?") ? "&" : "?") + "token=" + getToken() + (props.fileName === undefined ? "" : "&fileName=" + encodeURIComponent(props.fileName))
    }

    //only show if they have the permission to download data
    if(props.me !== undefined && props.me.accessProfile.systemAreas.includes("AccessAPI")) {
        return (
            <Tooltip title="Download excel">
                <IconButton 
                    aria-label="Download excel"
                    onClick={handleClick}
                >
                    <CloudDownloadIcon />
                </IconButton>
            </Tooltip>
        )
    } else {
        //no permission, hide the download button
        return (null)
    }
}

export default ExcelDownloadButton;