import React from 'react'
import dateFormat from 'dateformat'

import DataTable from '../../shared/dataTable/dataTable'
import recSheetLineEntityForm from './recSheetLineEntityForm';
import {formatMoney} from '../../../lib/general'
import LookupCell from '../../shared/dataTable/lookupCell'
import saleEntityForm from '../sales/entityForm/saleEntityForm'
import {getToken} from '../../../lib/auth'
import getApiUrl from '../../../lib/getApiUrl'


export default function RecSheetLinesTable(props) {

    const columnData = [
        { fieldName: 'recSheetLineID', default: true, name: 'ID', description: "The unchanging identifier of the rec sheet line"},
        { fieldName: 'saleDate', default: true, name: 'Sale date', description: "Date of the sale", renderCell: dr => dateFormat(new Date(dr.saleDate), "dd/mm/yyyy") },
        { fieldName: 'storeName', default: true, name: 'Store', description: "Name of the store the sale was made at" },
        { fieldName: 'orderRef', default: true, name: 'Order ref', description: "The order reference" },
        { fieldName: 'sku', default: true, name: 'SKU', description: "The SKU" },
        { fieldName: 'description', default: true, name: 'Description', description: "Description of the SKU"},
        { fieldName: 'revenue', default: true, name: 'Revenue', description: "Revenue receivable from this sale", renderCell: dr => dr.revenue + "" === "0" ? "-" : formatMoney(dr.revenue), alignRight: true},
        { fieldName: 'worksheet', default: true, name: 'Worksheet', description: "When imported from a spreadsheet this is the worksheet it came from"},
        { fieldName: 'file', default: false, name: 'Upload file', description: "The upload file from which this line came", renderCell: dr => <a href={getApiUrl() + "/file/" + dr.fileID + "/download?token=" + getToken()}>{dr.fileOriginalName}</a>},
        { fieldName: 'autoRecNotes', default: true, name: 'Auto rec', description: "Notes made by the auto reconciliation process, to understand why a match was or was not made"},
        { fieldName: 'saleID', default: true, name: 'Reconciled', description: "If the sale is reconciled this is the sale it's reconciled to", renderCell: (dr, handleDataChanged) => 
            dr.saleID === undefined || dr.saleID + "" === "0" ?
            "" :
            <LookupCell 
                id={dr.saleID} 
                entityForm={saleEntityForm}
                label={dr.saleID}
                me={props.me}
                onDataChanged={handleDataChanged}
            />
        },
    ];

    function filters() {
        var f = {
            recSheetID: props.recSheetID
        }
        //filter saleID based on if we want to see recd or not
        if(props.filter === "reconciled") {
            f.saleIDNot = 0
        }
        if(props.filter === "unreconciled") {
            f.saleID = 0
        }
        return f
    }

    return(
        <>
            <DataTable
                columnData={columnData}
                //overriding the recSheetID param on blankform to be the current one
                entityForm={{
                    ...recSheetLineEntityForm,
                    blankForm: {
                        ...recSheetLineEntityForm.blankForm,
                        recSheetID: props.recSheetID
                    }
                }}
                selectActions={props.selectActions}
                dataRefreshTime={props.dataRefreshTime}
                selectRefreshTime={props.selectRefreshTime}
                filters={filters()} 
                title="Rec Sheet Lines"
                enableCrud={true}
                crudRecordCheck={dr => dr.saleID === 0} //can only edit if it's not already reconciled
                enableRead={true}
                me={props.me}
            />
        </>
    )
}