import React, {useState, useEffect} from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import { Grid } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'

import CheckBox from '../../../shared/checkBox'
import AutoComplete from '../../../shared/autoComplete'
import authFetch from '../../../../lib/authFetch'
import AutoCompleteNative from '../../../shared/autoCompleteNative'
import DaysSelector from './daysSelector'

export default {
    displayName: "User",
    idField: "userID",

    getURL: id => "/users?userID=" + id,
    putURL: id => "/user/" + id,
    deleteURL: id => "/user/" + id,
    postURL: "/user",
    getListURL: "/users",
    maxWidth: "md",
    recordName: formVals => formVals.name,

    blankForm: {
        userID: 0,
        name: "", 
        email: "@vodafone.com",
        password: "",
        confirm: "",
        active: true,
        storeID: 0,
        accessProfileID: 0,
        defaultDaysBack: 180,
        fromHour: 0,
        toHour: 24,
        days: 0,
        proRateTargetsOnActual: false
    },
    form : function (props) {
        const [pr, setPR] = useState(false)
        const [hasUserSetup, setHasUserSetup] = useState(false)
       
        //load the users details, for tailoring the editability of the form based on the users systemAreas
        useEffect(() => {
            authFetch("/user/me")
            .then(r => setHasUserSetup(r.data.accessProfile.systemAreas.split(", ").includes("UserSetup")))
        }, [])

        function handleChange(event) {
            //merge it with the existing form vals and call the parent handler function
            props.onChange({...props.formVals, [event.target.id]: event.target.value})
        }

        function handlePR() {
            setPR(true)
        }

        const hourOptions = [
            {
                label: "00:00",
                value: 0
            },
            {
                label: "01:00",
                value: 1
            },
            {
                label: "02:00",
                value: 2
            },
            {
                label: "03:00",
                value: 3
            },
            {
                label: "04:00",
                value: 4
            },
            {
                label: "05:00",
                value: 5
            },
            {
                label: "06:00",
                value: 6
            },
            {
                label: "07:00",
                value: 7
            },
            {
                label: "08:00",
                value: 8
            },
            {
                label: "09:00",
                value: 9
            },
            {
                label: "10:00",
                value: 10
            },
            {
                label: "11:00",
                value: 11
            },
            {
                label: "12:00",
                value: 12
            },
            {
                label: "13:00",
                value: 13
            },
            {
                label: "14:00",
                value: 14
            },
            {
                label: "15:00",
                value: 15
            },
            {
                label: "16:00",
                value: 16
            },
            {
                label: "17:00",
                value: 17
            },
            {
                label: "18:00",
                value: 18
            },
            {
                label: "19:00",
                value: 19
            },
            {
                label: "20:00",
                value: 20
            },
            {
                label: "21:00",
                value: 21
            },
            {
                label: "22:00",
                value: 22
            },
            {
                label: "23:00",
                value: 23
            },
            {
                label: "24:00",
                value: 24
            },
        ]
    
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <h3>
                            General
                        </h3>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            type="text"
                            value={props.formVals.name}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("name")}
                            helperText={props.validationResult.failMessages["name"]}
                            fullWidth
                            disabled={props.disabled}
                        />
                        <TextField
                            margin="dense"
                            id="email"
                            label="Email (login)"
                            type="text"
                            value={props.formVals.email}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("email")}
                            helperText={props.validationResult.failMessages["email"]}
                            fullWidth
                            disabled={props.disabled || !hasUserSetup}
                        />
                        {
                            //showing the password boxes for a new user.  Else showing the password reset button
                            props.formVals.userID === 0 || pr ?
                                <div>
                                    <TextField
                                        margin="dense"
                                        id="password"
                                        label={pr ? "New password" : "Password"}
                                        type="password"
                                        value={props.formVals.password || ""}
                                        onChange={handleChange}
                                        error={props.validationResult.failMessages.hasOwnProperty("password")}
                                        helperText={props.validationResult.failMessages["password"]}
                                        fullWidth
                                        disabled={props.disabled}
                                    />
                                    <TextField
                                        margin="dense"
                                        id="confirm"
                                        label={pr ? "Confirm new password" : "Confirm password"}
                                        type="password"
                                        value={props.formVals.confirm || ""}
                                        onChange={handleChange}
                                        error={props.validationResult.failMessages.hasOwnProperty("confirm")}
                                        helperText={props.validationResult.failMessages["confirm"]}
                                        fullWidth
                                        disabled={props.disabled}
                                    />
                                </div>
                            :
                                <Button disabled={props.disabled} variant="contained" onClick={handlePR} style={{margin: "12px 0px 12px 0px"}}>
                                    Change password
                                </Button>
                        }
                        <AutoComplete 
                            multi={false}
                            id="accessProfileID"
                            label="Access profile"
                            onChange={handleChange}
                            placeholder="Choose..."
                            value={props.formVals.accessProfileID}
                            getURL="/accessProfiles"
                            valueField="accessProfileID"
                            labelField="name"
                            error={props.validationResult.failMessages.hasOwnProperty("accessProfileID")}
                            helperText={props.validationResult.failMessages["accessProfileID"]}
                            disabled={props.disabled || !hasUserSetup}
                        />
                        <h3>
                            Defaults
                        </h3>
                        <AutoComplete 
                            multi={false}
                            id="storeID"
                            label="Default store"
                            onChange={handleChange}
                            placeholder="Choose..."
                            value={props.formVals.storeID}
                            getURL="/stores"
                            valueField="storeID"
                            labelField="name"
                            error={props.validationResult.failMessages.hasOwnProperty("storeID")}
                            helperText={props.validationResult.failMessages["storeID"]}
                            disabled={props.disabled}
                        />
                        <TextField
                            margin="dense"
                            id="defaultDaysBack"
                            label="Sales list default days back"
                            type="text"
                            value={props.formVals.defaultDaysBack}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("defaultDaysBack")}
                            helperText={props.validationResult.failMessages["defaultDaysBack"]}
                            fullWidth
                            disabled={props.disabled}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">days back</InputAdornment>,
                            }}
                        />
                        <CheckBox 
                            id="proRateTargetsOnActual"
                            label="Pro rate targets on 'actual' tables"
                            onChange={handleChange}
                            value={props.formVals.proRateTargetsOnActual}
                            error={props.validationResult.failMessages.hasOwnProperty("proRateTargetsOnActual")}
                            helperText={props.validationResult.failMessages.proRateTargetsOnActual}
                            disabled={props.disabled}
                        />
                        {
                            props.disabled ? null :
                            <Alert severity="info" style={{margin: "10px"}}>
                                Changes to the user profile may not take effect until the user logs in again
                            </Alert>
                        }

                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h3>
                            Login Restrictions
                        </h3>
                        <CheckBox 
                            id="active"
                            label="User is active"
                            onChange={handleChange}
                            value={props.formVals.active}
                            error={props.validationResult.failMessages.hasOwnProperty("active")}
                            helperText={props.validationResult.failMessages["active"]}
                            disabled={props.disabled || !hasUserSetup}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} >
                                <AutoCompleteNative
                                    id="fromHour"
                                    value={props.formVals.fromHour}
                                    label="No login before"
                                    onChange={handleChange}
                                    optionLabel={o => o.label}
                                    optionValue={o => o.value}
                                    clearValue={0}
                                    options={hourOptions}
                                    error={props.validationResult.failMessages.hasOwnProperty("fromHour")}
                                    helperText={props.validationResult.failMessages["fromHour"]}
                                    placeholder={"00:00"}
                                    disabled={props.disabled || !hasUserSetup}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} >
                                <AutoCompleteNative
                                    id="toHour"
                                    value={props.formVals.toHour}
                                    label="No login after"
                                    onChange={handleChange}
                                    optionLabel={o => o.label}
                                    optionValue={o => o.value}
                                    clearValue={24}
                                    options={hourOptions}
                                    error={props.validationResult.failMessages.hasOwnProperty("toHour")}
                                    helperText={props.validationResult.failMessages["toHour"]}
                                    placeholder={"24:00"}
                                    disabled={props.disabled || !hasUserSetup}
                                />
                            </Grid>
                        </Grid>
                        <DaysSelector
                            id="days"
                            value={props.formVals.days}
                            label="Allow login on these days"
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("days")}
                            helperText={props.validationResult.failMessages["days"]}
                            disabled={props.disabled || !hasUserSetup}
                        />
                        <h3>
                            Data Restrictions
                        </h3>
                        <TextField
                            margin="dense"
                            id="salesMonthsBack"
                            label="Retrospective calendar months viewable?"
                            type="text"
                            value={props.formVals.salesMonthsBack}
                            onChange={handleChange}
                            error={props.validationResult.failMessages.hasOwnProperty("salesMonthsBack")}
                            helperText={props.validationResult.failMessages["salesMonthsBack"]}
                            fullWidth
                            disabled={props.disabled}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">months</InputAdornment>,
                            }}
                        />
                    </Grid>
                    </Grid>
            </div>
    
        )
    },
}
