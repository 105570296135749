import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom'

export default function CustomisationToolbar(props) {
    //calculate the tab value
    let tabValue = false
    //console.log("PLP is " + props.location.pathname + " and match is ")
    if(props.location.pathname === "/data/customisation") {tabValue = 0}
    if(props.location.pathname === "/data/customisation/favicon") {tabValue = 1}
    if(props.location.pathname === "/data/customisation/logo") {tabValue = 2}
    if(props.location.pathname === "/data/customisation/loginLogo") {tabValue = 3}

    return (
        <Tabs value={tabValue} variant="scrollable" scrollButtons="auto">
            <Tab label="Login background" component={Link} to={"/data/customisation"} />
            <Tab label="Favicon" component={Link} to={"/data/customisation/favicon"} />
            <Tab label="Logo" component={Link} to={"/data/customisation/logo"} />
            <Tab label="Login Logo" component={Link} to={"/data/customisation/loginLogo"} />
        </Tabs>
    );
}
