import React from 'react'
import {Route, Switch} from 'react-router-dom'

import DailyStatConsole from './dailyStats/dailyStatsConsole'
import DailyReport from './dailyReport/dailyReport'
import DailyTable from './dailyTable/dailyTable'

export default function Daily(props) {
    if(props.me === undefined || props.me.userID + "" === "0") {
        return null
    }

    return(
        <Switch>
            <Route path="/daily/dailyStats/:dailyStatDate/:storeID" render={renderProps => <DailyStatConsole {...renderProps} me={props.me} />} />
            <Route path="/daily/dailyStats" render={renderProps => <DailyStatConsole {...renderProps} me={props.me} />} />
            <Route path="/daily/dailyReport/:storeID/:saleDate" render={renderProps => <DailyReport {...renderProps} me={props.me} />} />
            <Route path="/daily/dailyReport/:storeID" render={renderProps => <DailyReport {...renderProps} me={props.me} />} />
            <Route path="/daily/dailyReport" render={renderProps => <DailyReport {...renderProps} me={props.me} />} />
            <Route path="/daily/dailyTable/:storeIDs/:splitBy/:fromDate/:toDate" render={renderProps => <DailyTable {...renderProps} me={props.me} />} />
            <Route path="/daily/dailyTable" render={renderProps => <DailyTable {...renderProps} me={props.me} />} />
        </Switch>
    )
}