import React, {useState} from 'react'
import Button from '@material-ui/core/Button'

import DefaultModal from './defaultModal'
//import ChangeModal from './changeModal'
import FileUploader from '../../../../shared/form/fileUploader'
import getApiUrl from '../../../../../lib/getApiUrl'

export default function LoginBackground(props) {
    const [defaultOpen, setDefaultOpen] = useState(false)
    const [imgDate, setImgDate] = useState(new Date())
    const [fileID, setFileID] = useState(0)

    const handleChange = function() {
        setDefaultOpen(false)
        setImgDate(new Date())
    }

    return(
        <div>
            <h1>
                Login Background Image
            </h1>
            <p>
                Here you can view and change the background image displayed behind the login screen.
            </p>
            <p>
                <strong>
                    For security reasons you are advised not to use images which would give a hacker information such as your contact details, addresses, company details, employee names, etc.
                </strong>
            </p>
            <img 
                src={getApiUrl() + "/file/noCache/loginBackground"}
                style={{
                    maxWidth: "350px",
                    margin: "10px"
                }}
                key={imgDate}
            />
            <br/>
            <div style={{maxWidth: "350px"}}>
                <FileUploader 
                    onChange={() => setImgDate(new Date())} 
                    value={fileID}
                    id="fileID"
                    disabled={false}
                    //error={props.validationResult.failMessages.hasOwnProperty("fileID")}
                    //helperText={props.validationResult.failMessages["fileID"]}
                    library="loginBackground"
                    label="a new image"
                    accept="image/jpeg"
                    urlType="image"
                />
            </div>
            <Button
                onClick={() => setDefaultOpen(true)}
                variant="contained"
                color="default"
                style={{margin: "10px"}}
            >
                Reset
            </Button>
            <DefaultModal
                open={defaultOpen}
                onChange={handleChange}
            />
        </div>
    )
}