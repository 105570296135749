import React, {useEffect, useState} from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {withRouter} from 'react-router-dom'
import { Card, CardContent, CardActions, FormHelperText } from '@material-ui/core'
import getApiUrl from '../../../lib/getApiUrl'

import {logIn} from '../../../lib/auth'
import ValidationSummary from '../../shared/form/validationSummary'
import LoginTitle from './loginTitle'

var backDiv = {
    backgroundImage: "url(" + getApiUrl() + "/file/loginBackground)",
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "table",
    position: "absolute",
    top: 0,
    left: 0,
}

  
var middle = {
    display: "table-cell",
    verticalAlign: "middle",
}

var inner = {
    marginLeft: "auto",
    marginRight: "auto",
    width: "400px",
    /*whatever width you want*/
}

export default withRouter(function Login(props) {
    const [formVals, setFormVals] = useState({email: "", password: ""})
    const [validationResult, setValidationResult] = useState({fail: false, failSummary: '', failMessages: {}})
    const [loading, setLoading] = useState(false)

    function handleChange(event) {
        setFormVals({...formVals, [event.target.id]: event.target.value})
    }

    function handleLogin() {
        //event.preventDefault();
        setLoading(true)
        //try to get the jwt from the server
        fetch(getApiUrl() + "/user/login", {method: 'POST', body: JSON.stringify({...formVals}), headers: { "Content-Type": "application/json" }})
        .then(checkResponse)
        .then(data => {
            //get the token from the response
            //need to save the token as a cookie
            logIn(data.data.token, data.user.userID)
            //now reload as logged in user
            window.location.reload()
        })
        .catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    function checkResponse(res) {
        setLoading(false)
        //var that = this
        return new Promise(function (resolve, reject) {
            if(res.status === 201) {
                //call was successful
                resolve(res.json())
            } else {
                //call was not successful, examine the return for validation messages
                res.json()
                .then(function(data) {
                    setValidationResult(data.validationResult)
                    //finally reject the promise 
                    reject(data.validationResult.failSummary)
                })
            }
        })
    }

    function handleKeyPress(e) {
        if(e.key === "Enter") {
            handleLogin()
        }
    }

    const fields = [
        {
            id: 'email',
            label: 'Email',
            type: 'text',
        },
        {
            id: 'password',
            label: 'Password',
            type: 'password',
        },
    ]

    return(
        <div style={backDiv} >
            <div style={middle}>
                <div style={inner}>
                    <Card elevation={3} style={{padding: "5px 20px"}}>
                        <CardContent>
                            <LoginTitle />
                                {
                                    fields.map(field => {
                                        return(
                                            <TextField
                                                key={field.id}
                                                fullWidth
                                                error={validationResult.failMessages.hasOwnProperty(field.id)}
                                                helperText={validationResult.failMessages[field.id]}
                                                id={field.id}
                                                type={field.type}
                                                label={field.label}
                                                value={formVals[field.id]}
                                                onChange={handleChange}
                                                onKeyPress={handleKeyPress}
                                            />
                                        )
                                    })
                                }
                                <FormHelperText>
                                Authorised access only.  For help logging in contact your line manager.  
                                </FormHelperText>
                        </CardContent>
                        <CardActions >
                            <div style={{width: "100%"}} >
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleLogin}
                                    style={{marginRight: "10px", width: "110px"}}
                                    disabled={loading}
                                >
                                    {loading ? "Loading..." : "Log in"}
                                </Button>
                            </div>
                            <div style={{width: "100%"}} >
                                <ValidationSummary validationResult={validationResult} />
                            </div>
                        </CardActions>

                    </Card>
                </div>
            </div>
        </div>
    );
})
