import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import authFetch from '../../../../../lib/authFetch';


export default function DefaultModal(props) {
    function handleAction() {
        //make call to reset
        authFetch("/file/loginBackground", {method: "DELETE"})
        .then(r => {
            //close the dialogue
            props.onChange()
        })
    }

    const noFullscreen = useMediaQuery("(min-width:1000px)")

    return(
        <Dialog open={props.open} fullScreen={!noFullscreen} maxWidth="sm" fullWidth={true} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                Reset to Default?
            </DialogTitle>
            <DialogContent>
                <p>Are you sure you want to reset the login background image to the default?</p>
            </DialogContent>
            <DialogActions>
                <Button color="default" onClick={props.onChange} variant="contained">
                    Close
                </Button>
                <Button color="secondary" onClick={handleAction} variant="contained">
                    Reset to Default Image
                </Button>
            </DialogActions>
      </Dialog>
    )
}