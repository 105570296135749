import React, {useEffect, useState} from 'react'
import getApiUrl from '../../../lib/getApiUrl'


export default function LoginTitle(props) {
    const [appName, setAppName] = useState(undefined)
    const [fileSet, setFileSet] = useState(false)

    useEffect(() => {
        fetch(getApiUrl() + "/file/loginLogo", {method: "GET"})
        .then(r => {
            //did we get a file?
            if(r.status === 200 || r.status === 304) {
                setFileSet(true)
            } else {
                setFileSet(false)
            }
        })
    }, [])

    //get app name from API
    useEffect(() => {
        //prefix the API url
        let url = getApiUrl() + "/appName"
        //return a normal fetch and auth check
        return fetch(url, {
            method: "GET",
            headers: {
                ["Content-Type"]: "application/json"
            }
        })
        .then(response => response.json())
        .then(r => setAppName(r.data))
        .catch(err => {
            console.log(err)
        })
    }, [])

    //do we have a file set?
    if(fileSet) {
        //show the image, max width 300px
        return(
            <img
                src={getApiUrl() + "/file/loginLogo"}
                style={{
                    maxWidth: "300px",
                    margin: "10px"
                }}
            />
        );
    } else {
        //just show the text of the app name
        return(
            <h3>
                {
                    appName ? appName : ""
                }
            </h3>
        );
    }
}
