import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import dateFormat from 'dateformat'
import LinearProgress from '@material-ui/core/LinearProgress';
import purple from '@material-ui/core/colors/purple';

//icons
import PrevIcon from '@material-ui/icons/FastRewindOutlined'
import NextIcon from '@material-ui/icons/FastForwardOutlined'

//shows the current month date, and links to the next and prev months dates
export default function MonthHeader(props) {

    function handleNext() {
        props.onChange(addMonths(1, props.monthDate))
    }

    function handlePrev() {
        props.onChange(addMonths(-1, props.monthDate))
    }

    function addMonths(months, toDate) {
        //console.log("ToDate: " + dateFormat(toDate, "UTC:yyyy-mm-dd HH:MM:ss") + " adding " + months + " months gives " + dateFormat(new Date(new Date(toDate).setUTCMonth(toDate.getUTCMonth() + months)), "UTC:yyyy-mm-dd HH:MM:ss"))
        var theToDate = new Date(toDate)
        var sameDayAddingMonths = new Date(theToDate.setUTCMonth(toDate.getUTCMonth() + months))
        return sameDayAddingMonths
    }

    //function to calculate the number of months between today and the monthDate (passed date object)
    function currentMonthsBack() {
        var today = new Date()
        var months
        months = (props.monthDate.getUTCFullYear() - today.getUTCFullYear()) * 12
        months -= today.getUTCMonth()
        months += props.monthDate.getUTCMonth()
        return months * -1
    }

    //is the user restricted by sales months back?
    function isRestricted() {
        //only restricted if it's not 0
        if(props.me.salesMonthsBack !== 0 && currentMonthsBack() >= props.me.salesMonthsBack) {
            return true
        } else {
            return false
        }
    }

    return (
        <Grid container spacing={3} style={{alignItems: "center"}}>
            <Grid item xs={4} style={{alignItems: "center"}}>
                <Button
                    variant="contained"
                    color="default"
                    startIcon={<PrevIcon />}
                    onClick={handlePrev}
                    disabled={isRestricted()}
                >
                    {
                        isRestricted() ? 
                            "Max " + props.me.salesMonthsBack + " mon"
                        :
                            dateFormat(addMonths(-1, props.monthDate), "UTC:mmm")
                    }
                </Button>
            </Grid>
            <Grid item xs={4} style={{alignItems: "center", textAlign: "center", minHeight: "92px"}}>
                {
                    props.loading ? 
                        <LinearProgress style={{ marginTop:"30px", color: purple[500], width: "100%", display: "inline-block"}} />
                    :
                        <h2>
                            {dateFormat(props.monthDate, "UTC:mmm yyyy")}
                            {
                                props.children
                            }
                        </h2>
                }
            </Grid>
            <Grid item xs={4} style={{alignItems: "center", textAlign: "right"}}>
                <Button
                    variant="contained"
                    color="default"
                    endIcon={<NextIcon />}
                    onClick={handleNext}
                >
                    {dateFormat(addMonths(1, props.monthDate), "UTC:mmm")}
                </Button>
            </Grid>
        </Grid>
    )
}