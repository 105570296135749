import React, {useState} from 'react'
import { Grid } from '@material-ui/core'
import dateFormat from 'dateformat'

import AutoCompleteNative from '../../../shared/autoCompleteNative'
import MultiSelect from '../../../shared/form/multiSelect'
import DatePicker from '../../../shared/form/datePicker'
import TableSet from '../../landing/tables/tableSet'
import RevenueCommissionSwitchTitle from '../../../shared/title/revenueCommissionSwitchTitle'

import { getDefaultStoreID, accessibleStores } from '../../../../lib/general'

export default function DailyTable(props) {
    const [loading, setLoading] = useState(false)
    const [tableSet, setTableSet] = useState(null)


    //needs a me object to work, so return null if they came straight to this page
    if(props.me === null || props.me === undefined || props.me.userID === undefined || props.me.userID + "" === "0") {
        return null
    }
    //if any of the params are not right then redirect to defaults
    var storeIDsTest = new RegExp("^[0-9]+(,[0-9]+)*$")
    var dateTest = new RegExp("^[12][019][0-9][0-9]-[01]{0,1}[0-9]-[0-3]{0,1}[0-9]$")
    if(
        !(storeIDsTest.test(props.match.params.storeIDs)) ||
        !(dateTest.test(props.match.params.fromDate)) ||
        !(dateTest.test(props.match.params.toDate)) ||
        !(["attributableToUserID", "storeID", "storeStoreTypeID"].includes(props.match.params.splitBy))
    ) {
        //dodgy parameters, send them to the defaults
        props.history.push("/daily/dailyTable/" + getDefaultStoreID(props.me) + "/attributableToUserID/" + dateFormat(new Date(), "yyyy-mm-dd") + "/" + dateFormat(new Date(), "yyyy-mm-dd"))
        return null
    }

    function handleChangeSplitBy(e) {
        props.history.push("/daily/dailyTable/" + props.match.params.storeIDs + "/" + e.target.value + "/" + props.match.params.fromDate + "/" + props.match.params.toDate)
    }

    function handleFromChangeDate(e) {
        props.history.push("/daily/dailyTable/" + props.match.params.storeIDs + "/" + props.match.params.splitBy + "/" + dateFormat(e.target.value, "yyyy-mm-dd") + "/" + props.match.params.toDate)
    }

    function handleToChangeDate(e) {
        props.history.push("/daily/dailyTable/" + props.match.params.storeIDs + "/" + props.match.params.splitBy + "/" + props.match.params.fromDate + "/" + dateFormat(e.target.value, "yyyy-mm-dd"))
    }

    function handleChangeStores(e) {
        //return value should be an array
        if (Array.isArray(e.target.value)) {
            props.history.push("/daily/dailyTable/" + e.target.value.join(",") + "/" + props.match.params.splitBy + "/" + props.match.params.fromDate + "/" + props.match.params.toDate)
        }
    }

    //function to calculate the number of months between today and the parameter date
    function currentMonthsBack() {
        var today = new Date()
        var paramDate = new Date(props.match.params.fromDate)
        var months
        months = (paramDate.getUTCFullYear() - today.getUTCFullYear()) * 12
        months -= today.getUTCMonth()
        months += paramDate.getUTCMonth()
        return months * -1
    }

    //are we going back too far?
    function tooFarBack() {
        return currentMonthsBack() > props.me.salesMonthsBack
    }

    const splitBys = [
        {
            value: "attributableToUserID",
            label: "Users"
        },
        {
            value: "storeID",
            label: "Stores",
        },
        {
            value: "storeStoreTypeID",
            label: "Store types"
        }
    ]

    const stores = accessibleStores(props.me)

    

    return (
        <>
            <RevenueCommissionSwitchTitle
                me={props.me}
                title="Custom Date Table"
            />
            <Grid spacing={1} container>
                <Grid item xs={12} md={3}>
                    <AutoCompleteNative
                        id="splitBy"
                        value={props.match.params.splitBy}
                        label={"Rows"}
                        onChange={handleChangeSplitBy}
                        optionLabel={o => o.label}
                        optionValue={o => o.value}
                        options={splitBys}
                        placeholder={"Split by..."}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <MultiSelect
                        id="storeID"
                        multiple
                        value={
                                (props.match.params.storeIDs + "")
                                .split(",")
                                .filter(x => 
                                    accessibleStores(props.me)
                                    .map(s => s.storeID + "")
                                    .includes(x)
                                )
                            }
                        label="Stores"
                        onChange={handleChangeStores}
                        optionLabel={o => o.name}
                        optionValue={o => o.storeID + ""}
                        options={accessibleStores(props.me)}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DatePicker 
                        id="fromDate"
                        label="Date from"
                        onChange={handleFromChangeDate}
                        value={new Date(props.match.params.fromDate)}
                        error={tooFarBack()}
                        helperText={tooFarBack() ? props.me.salesMonthsBack + " months max restriction" : undefined}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <DatePicker 
                        id="toDate"
                        label="Date to"
                        onChange={handleToChangeDate}
                        value={new Date(props.match.params.toDate)}
                    />
                </Grid>
            </Grid>
            <TableSet
                fromDate={props.match.params.fromDate}
                toDate={props.match.params.toDate}
                me={props.me}
                loading={loading}
                allStores={stores}
                storeIDs={props.match.params.storeIDs.split(",")}
                splitBy={props.match.params.splitBy}
                onLoadingChange={l => setLoading(l)}
                tableSet={tableSet}
                onNewTableSet={ts => setTableSet(ts)} 
                drhOnly={true} //no targets or different time slices, just the date specified
            />
        </>
    )
}