import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button'

import DefaultModal from './defaultModal'
import FileUploader from '../../../../shared/form/fileUploader'
import getApiUrl from '../../../../../lib/getApiUrl'
import { getToken } from '../../../../../lib/auth'

export default function LoginLogo(props) {
    const [defaultOpen, setDefaultOpen] = useState(false)
    const [imgDate, setImgDate] = useState(new Date())
    const [fileID, setFileID] = useState(0)
    const [fileSet, setFileSet] = useState(false)

    useEffect(() => {
        fetch(getApiUrl() + "/file/noCache/loginLogo", {method: "GET", headers: {"x-access-token": getToken()}})
        .then(r => {
            //did we get a file?
            if(r.status === 200) {
                setFileSet(true)
            } else {
                setFileSet(false)
            }
        })
    }, [
        imgDate
    ])

    const handleChange = function() {
        setDefaultOpen(false)
        setImgDate(new Date())
    }

    return(
        <div>
            <h1>
                Login Logo Image
            </h1>
            <p>
                Here you can upload a login logo image into your system.  It appears in the login screen, if set.  Don't use a file too large or it may slow down your system!
            </p>
            {
                fileSet ?
                    <img 
                        src={getApiUrl() + "/file/noCache/loginLogo"}
                        style={{
                            maxWidth: "300px",
                            margin: "10px"
                        }}
                        key={imgDate}
                    />   
                :
                    <><em>(No login logo set)</em><br/></>
                    
            }
            <br/>
            <div style={{maxWidth: "300px"}}>
                <FileUploader 
                    onChange={() => setImgDate(new Date())} 
                    value={fileID}
                    id="fileID"
                    disabled={false}
                    //error={props.validationResult.failMessages.hasOwnProperty("fileID")}
                    //helperText={props.validationResult.failMessages["fileID"]}
                    library="loginLogo"
                    label="a new login logo (.png, .jpg)"
                    accept="any"
                    urlType="image"
                />
            </div>
            <Button
                onClick={() => setDefaultOpen(true)}
                variant="contained"
                color="default"
                style={{margin: "10px"}}
            >
                Reset
            </Button>
            <DefaultModal
                open={defaultOpen}
                onChange={handleChange}
            />
        </div>
    )
}