import React from 'react'
import APIFooter from '../../../shared/api/apiFooter'
import getApiUrl from '../../../../lib/getApiUrl'

const details = {
    name: "DailyStats",
    url: getApiUrl() + "/dailyStats",
    params: [
        {
            param: "dailyStatDate",
            name: "Stats date (first day of month)",
            format: "yyyy-mm-dd",
            example: "2020-04-01",
            regex: "^[12][019][0-9][0-9]-[01]{0,1}[0-9]-[0-3]{0,1}[0-9](T[0-9][0-9]:[0-9][0-9]:[0-9][0-9]\.[0-9][0-9][0-9]Z)?$",
        },        
        {
            param: "storeNameLower",
            name: "Store name lower case",
            format: "text",
            example: "london",
            regex: "^.{0,50}$",
        },
    ]
}

export default function DailyStatsAPIData(props) {
    return (
        <APIFooter
            me={props.me}
            details={details}
        />
    )
}