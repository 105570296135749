import React from 'react'
import APIFooter from '../../shared/api/apiFooter'
import getApiUrl from '../../../lib/getApiUrl'

const details = {
    name: "Sales",
    url: getApiUrl() + "/sales",
    params: [
        {
            param: "saleDateGEQ",
            name: "Sale date from",
            format: "yyyy-mm-dd",
            example: "2020-03-31",
            regex: "^[12][019][0-9][0-9]-[01]{0,1}[0-9]-[0-3]{0,1}[0-9](T[0-9][0-9]:[0-9][0-9]:[0-9][0-9]\.[0-9][0-9][0-9]Z)?$",
        },
        {
            param: "saleDateLEQ",
            name: "Sale date to",
            format: "yyyy-mm-dd",
            example: "2020-04-30",
            regex: "^[12][019][0-9][0-9]-[01]{0,1}[0-9]-[0-3]{0,1}[0-9](T[0-9][0-9]:[0-9][0-9]:[0-9][0-9]\.[0-9][0-9][0-9]Z)?$",
        },        
        {
            param: "storeNameLower",
            name: "Store name lower case",
            format: "text",
            example: "london",
            regex: "^.{0,50}$",
        },
        {
            param: "attributableToUserNameLower",
            name: "Sale by name (lower case)",
            format: "text",
            example: "john smith",
            regex: "^.{0,50}$",
        },
        {
            param: "skuLower",
            name: "SKU lower case",
            format: "text",
            example: "1234abc",
            regex: "^.{0,25}$",
        },
        {
            param: "currentHistoryStep",
            name: "Sale status",
            format: "text",
            example: "modified",
            regex: "^.{0,50}$",
        },
    ]
}

export default function SalesAPIData(props) {
    return (
        <APIFooter
            me={props.me}
            details={details}
        />
    )
}