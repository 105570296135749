import React, {useState, useEffect} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {Route, Link} from 'react-router-dom'
import HomeIcon from '@material-ui/icons/Home'
import dateFormat from 'dateformat';


//project
import AccountMenu from './accountMenu'
import LeftMenuItems from './leftMenuItems'
import PageRoutes, {ToolbarRoutes} from './pageRoutes'
import authFetch from '../../../lib/authFetch'
import userEntityForm from '../../pages/data/users/userEntityForm'
import accessProfileEntityForm from '../../pages/data/users/accessProfileEntityForm'
import getApiUrl from '../../../lib/getApiUrl'

const drawerWidth = 240;

const styles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    appFrame: {
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        position: 'absolute',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        minHeight: "48px", 
        maxHeight: "48px", 
        display: "flex", 
        justifyContent: "space-between",
        flexDirection: "row",
        //width: "100%"
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
        }),
    },
    'appBarShift-left': {
        marginLeft: drawerWidth,
    },
    menuButton: {
        marginLeft: 9,
        marginRight: 15,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        height: '40px'
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
        }),
    },
    'contentShift-left': {
        marginLeft: 0,
    },
}));



export default function LeftMenu(props) {
    const blankMe = {
        ...userEntityForm.blankForm,
        accessProfile: {
            ...accessProfileEntityForm.blankForm,
            accessProfileStores: []
        },
        site: {},
        //the special value revenueCommission lives in me.  It stays in me for the session.  It says whether the user is seeing revenue or commission.  This is the function to call to change it
        handleNewMe: handleNewMe,
        //and here is a special function to call if you are aware of a change to me and want to load a new one
        getNewMeFromAPI: () => getNewMeFromAPI()
    }

    const [open, setOpen] = useState(false)
    const [me, setMe] = useState({...blankMe})
    const [cardUsers, setCardUsers] = useState("0")
    const [appName, setAppName] = useState(undefined)
    const [fileSet, setFileSet] = useState(false)

    useEffect(() => {
        fetch(getApiUrl() + "/file/logo", {method: "GET"})
        .then(r => {
            //did we get a file?
            if(r.status === 200 || r.status === 304) {
                setFileSet(true)
            } else {
                setFileSet(false)
            }
        })
    }, [])

    useEffect(() => {
        //prefix the API url
        let url = getApiUrl() + "/appName"
        //return a normal fetch and auth check
        return fetch(url, {
            method: "GET",
            headers: {
                ["Content-Type"]: "application/json"
            }
        })
        .then(response => response.json())
        .then(r => setAppName(r.data))
        .catch(err => {
            console.log(err)
        })
    }, [])

    function handleNewMe(newMe) {
        setMe({
            ...newMe,
        })
    }

    function getNewMeFromAPI() {
        var newMe = {}
        authFetch("/user/me")
        .then(r => {
            //merge the new me in with the current one
            setMe({
                ...me,
                ...r.data,
            })
        })
    }

    //load the users details, for tailoring menus, the account menu, etc
    useEffect(() => {
        getNewMeFromAPI()
    }, [])

    function handleMenuSelect(newPath) {
        //console.log("menu redirect to " + newPath)
        props.history.push(newPath)
        //and close the menu
        setOpen(false)
    }


    const handleDrawer = () => {
        setOpen(!open);
    };

    const classes  = styles();

    const wideEnoughForTitle = useMediaQuery('(min-width:800px)');

    var homeUrl = "/"
    if(cardUsers + "" !== "0") {
        //we have some valid data to make the url
        homeUrl = "/landing/0/" + dateFormat(new Date(), "yyyy-mm-dd") + "/" + cardUsers
    }

    return (
        <div className={classes.root}>
            <div  className={classes.appFrame}>
                <AppBar className={classNames(classes.appBar)}>
                    <IconButton
                        color="inherit"
                        aria-label="Open left menu"
                        onClick={handleDrawer}
                        className={classes.menuButton}
                        style={{width: "48px", display: "flex", marginRight:"8px"}}
                    >
                        <MenuIcon />
                    </IconButton>
                    {
                        wideEnoughForTitle ? 
                                <div style={{width:"130px", height: "48px", display: "flex"}}>
                                    <Link to={homeUrl} style={{lineHeight: "48px", color: "white", textDecoration: "none"}}>
                                        {
                                            fileSet ?
                                                <img
                                                    src={getApiUrl() + "/file/logo"}
                                                    style={{
                                                        maxWidth: "130px",
                                                        margin: "0px",
                                                        height: "48px"
                                                    }}
                                                />
                                            :
                                                (appName ? appName : "")
                                        }
                                    </Link>
                                </div> 
                        : 
                            <div style={{width:"48px", height: "48px", display: "flex", alignItems:"center"}}>
                                <Link to={homeUrl} style={{lineHeight: "48px", color: "white", textDecoration: "none", display: "flex"}}>
                                    <HomeIcon />
                                </Link>
                            </div> 
                    }
                    <ToolbarRoutes me={me} />
                    <Route key="/" path="/" render={(routeProps) => <AccountMenu {...routeProps} me={me} />} />
                </AppBar>
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    onClose={() => setOpen(false)}
                    keepMounted
                >
                    <div className={classes.drawerHeader} style={{maxHeight: "48px", minHeight: "48px", width:"180px", display:"flex", justifyContent:"space-between"}}>
                        <IconButton
                                color="inherit"
                                aria-label="Open left menu"
                                onClick={handleDrawer}
                            >
                            <ArrowBackIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <LeftMenuItems handleMenuSelect={handleMenuSelect} me={me} />
                </Drawer>
                <PageRoutes 
                    mainClassName={classNames(classes.content, classes['content-left'])} 
                    drawerHeaderClassName={classes.drawerHeader} 
                    me={me} 
                    onCardUsersChange={newCU => setCardUsers(newCU)}
                />
            </div>
        </div>
    );
}
