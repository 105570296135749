import React from 'react'
import {Route, Switch} from 'react-router-dom'

import LoginBackground from './background/loginBackground'
import Favicon from './favicon/favicon'
import Logo from './logo/logo'
import LoginLogo from './loginLogo/loginLogo'

export default function Customisation(props) {
    return(
        <Switch>
            <Route path="/data/customisation/favicon" render={renderProps => <Favicon {...renderProps} me={props.me} />} />
            <Route path="/data/customisation/logo" render={renderProps => <Logo {...renderProps} me={props.me} />} />
            <Route path="/data/customisation/loginLogo" render={renderProps => <LoginLogo {...renderProps} me={props.me} />} />
            <Route path="/data/customisation" render={routeProps => <LoginBackground {...routeProps} me={props.me} />} />
        </Switch>
    )
}